.navWrapper{
    padding:5px;
    box-sizing: border-box;
    width: 100vw;
    height: 70px;
    position:fixed;
    top:0px;
    left:0px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    /* border: 1px solid black; */
}
.rangeWrapper{
    width:70%;
    /* border:1px solid red; */
    display: flex;
    justify-content: center;
    align-self: center;
    /* flex-direction: column; */
}
.navWrapper input{
    width:50%;
    transform: scale(2);
}
.slideIcons{
    width:70%;
    /* border: 1px solid green; */
    position: relative;
    margin-top:9px;
}
.slideIcons > div{
    position: absolute;
    top:0;
}
.slideIcons > div > img{
    width: 20px;
    margin-left: -10px
}
p.infoMarker{
    top:37px;
    width:100vw;
    position: absolute;
    margin-left: -10px;
    font-size: 0.7em;
    animation-name: disappear;
    animation-timing-function: ease;
    animation-duration: 4s;
    animation-fill-mode: forwards;
}

@keyframes disappear{
    0%{
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    80%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
/* .navWrapper>div{
    width: 30vw;
    height: 20vw;
    border:1px black solid;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:5px;
    box-sizing: border-box;
}
.navWrapper>div>div{

    font-size: 0.7em;
    text-align: center;
}
.navWrapper>div>div>p{
    margin:0;
}
.selected{
    background-color: black;
    color: white;
    font-weight: bold;
} */
/* .selected p{
    background-color: black;
} */