/* .wrapper{
  color: red;
} */
.app{
  overflow: hidden;
}

.dataFetchWarning{
  position: fixed;
  left:17px;
  bottom:0;
  color: #ff0000;
}