.packetWrapper{
    position: relative;
    width: 100vw;
    height: 100vh;
}
.packetInnerWrapper{
    display: inline-block;
    box-sizing: border-box;
}
.displayWrapper{
    /* display: flex;
    width:100vw;
    flex-wrap: wrap; */
    width: 100vw;
    height: 100vh;
    /* display: inline-block;
    box-sizing: border-box; */
    /* border: black 1px solid; */
    position: absolute;
    top:0px;
    left:0px;
    pointer-events:none;
}

.item {
    /* width: 400px; */
    width: fit-content;
    /* height: 100px; */
    position: absolute;
    /* border: 1px solid black; */
    /* overflow: hidden; */
    white-space: pre-wrap;
    transform-origin: top left;
}

.itemEnter p{
    color: black;
    background-color: black;
}
.itemEnterActive p{

}
.itemEnterDone p{
    animation-name: enteringP;
    animation-duration: 2.5s;
    animation-timing-function: linear;    
}


.itemEnter img{
    filter: brightness(0);
}

.itemEnterDone img{
    animation-name: enteringIMG;
    animation-duration: 2.5s;
    animation-timing-function: linear;    
}

.itemEnter audio{
    filter: brightness(0);
}

.itemEnterDone audio{
    animation-name: enteringIMG;
    animation-duration: 2.5s;
    animation-timing-function: linear;    
}

@keyframes enteringP{
    0%{
        /* opacity: 0; */
        color: black;
        background-color: black;
        
        /* box-shadow: 0 0 20px 20px transparent; */
    }

    90%{
        color: black;
        background-color: black; 
        /* box-shadow: 0 0 20px 20px black */
    }
    100%{
        /* opacity: 1; */
        color: black;
        background-color: transparent;
        /* box-shadow: 0 0 20px 20px transparent */
    }
}

@keyframes enteringIMG{
    0%{
        filter: brightness(0);
    }

    90%{
        filter: brightness(0);
    }
    100%{
        filter: brightness(1);
    }
}