/* .wrapper{
  color: red;
} */
.app{
  /* overflow: hidden; */
  border: 5px black solid;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  /* outline-offset: -5px; */
}

/* .dataFetchWarning{
  position: fixed;
  left:10px;
  bottom:0;
  color:red;
} */