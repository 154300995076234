/* .wrapper{
    width: 100vw;
    height: 60px;
    border: 1px solid black;
    position: fixed;
    top:0;
    left:0;
    background: rgba(255, 255, 255, 0.5);
    z-index:2;
}

.inputWrapper{
    border: 1px solid red;
    width:100%;
    height:50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;

}
.range{
    transform: rotate(180deg);
    width:100%;
} */

:root {
    --wrapper-height: 60px;
    --rangeWrapper-height: 30px;
    --slider-margin-top: 10px;
}

.wrapper{
    width:100vw;
    height: var(--wrapper-height);
    /* border: 1px solid black; */
    position: fixed;
    top:0;
    right:0;
    /* background: rgba(255, 255, 255, 0.5); */
    z-index:2;
    pointer-events: none;
}

.inputWrapper{
    pointer-events: all;
    /* border: 1px solid red; */
    width:100vw;
    /* height:var(--rangeWrapper-height); */
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    box-sizing: border-box;
    
    /* transform-origin: calc(-1*var(--slider-margin-top))  calc(var(--wrapper-height) - var(--slider-margin-top)); */
    margin-top: var(--slider-margin-top);
    
}
.rangeMarks{
    width:100%;
    /* height:90px; */
    /* border: 1px solid red; */
    position: relative;
    margin-top: 7px;
}
.rangeMark{
    top:0;
    position: absolute;
}

/* .rangeMark > p{
    font-size:1em;
    margin:0;
    margin-left: -11px;
    background-color: black;
} */
.rangeMark > img{
    width:22px;
    margin-left:-5px;
}
.rangeMark:last-child > img{
    margin-left:-14px;
}


p.infoMarker{
    top:27px;
    width:100vw;
    position: absolute;
    margin-left: 0px;
    font-size: 0.7em;
    /* animation-name: disappear;
    animation-timing-function: ease;
    animation-duration: 4s;
    animation-fill-mode: forwards; */
}
@keyframes disappear{
    0%{
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    80%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
.range{
    width: 100%;
    /* pointer-events: all; */
    transform: rotate(180deg);
}

