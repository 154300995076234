.infoinfoOverlayWrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    position: absolute;
    top:0px;
    left: 0px;
    z-index: 3;
    background-color: rgba(0,0,0,0.8);
    justify-content: center;
    align-items: center;
    /* display: block; */
    /* margin:auto; */
    overflow: scroll;
}
.wrapper{
    /* display: inline-block; */
    width: 100%;
    /* border: 1px solid red; */
    max-width: 450px;
    background-color: honeydew;
    padding:10px;
    box-sizing: border-box;

}
.wrapper a{
    color:black;
}
.wrapper>div{
    /* border: 1px solid green; */
}
.headline{
    margin-bottom: 10px;
}
.names{
    margin-top:0px;
}
.fetching{
    color:blue;
    /* text-align: right; */
}
.close{
    display: inline-block;
    color:blue;
    font-weight: bold;
    /* text-align: right; */
    text-decoration: underline;  
    cursor: pointer;
}
.close:hover{
    font-weight: bold;
    font-style: italic;
}

