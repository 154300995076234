.infoWrapper{
    position: fixed;
    right: 4px;
    bottom: 13px;
}
.infoWrapper p{
    display: inline;
    margin-right: 15px;
    font-size: 0.9em;
    opacity: 0.6;
}
.infoWrapper p:hover{
    opacity: 1;
    background-color: black;
    color:white;
}
.infoWrapper a{
    color: #ff0000;
}

.audioButton{
    cursor: pointer;
}